.confirmModal {
  font-family: 'Open Sans', sans-serif;

  .cancelButton:not(:disabled):hover {
    color: var(--color-orange-5);
    border-color: var(--color-orange-5);
  }

  .delete {
    background-color: var(--color-red-2) !important;
    border-color: var(--color-red-2);

    &:not([disabled]):hover {
      background-color: var(--color-red) !important;
      border-color: var(--color-red);
    }
  }
}
