.period {
  display: grid;
  grid-gap: 8px;
  grid-template-columns: 1fr auto;
  border-bottom: 1px solid var(--color-grey-3);
  align-items: center;
  padding: 8px 0;

  &.isLast {
    border-bottom: none;
  }

  .days {
    margin: 0;
  }

  .dates {
    font-size: 12px;
    margin: 0;
    color: var(--color-secondary-text);
  }
}
