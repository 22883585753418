.wrapper {
  display: grid;
  grid-template-rows: repeat(3, 55px);
  grid-gap: 8px;
  align-items: flex-start;

  .buttons {
    width: 128px;
    height: 54px !important;
    border-radius: 4px;
    text-align: start;
    color: var(--color-secondary-text) !important;
    text-decoration: none !important;

    .counter {
      color: var(--color-black-2) !important;
      font-weight: 600;
    }

    &:not([disabled]):active,
    &:not([disabled]):focus,
    &:not([disabled]):hover {
      color: var(--color-secondary-text) !important;
    }
  }

  .active {
    background-color: var(--color-orange-1);
  }
}

