.content {
  width: calc(100% - 48px);
  max-width: 1920px;
  padding: 24px;
  margin: 24px;
  min-height: calc(100vh - 48px);
  background: #fff;
  position: relative;
}

.logoutWrapper {
  background-color: var(--color-grey);
  padding: 16px 0;
  position: fixed;
  left: 0;
  width: 200px;
  bottom: 0;
}

.logout {
  width: 100%;
  text-align: left;
  padding: 4px 24px;
  background-color: transparent !important;
  font-family: 'Open Sans', sans-serif;

  svg {
    transform: rotate(-90deg);

    path {
      fill: var(--color-orange-5);
    }
  }

  &:hover {
    color: var(--color-orange-5) !important;
  }
}
