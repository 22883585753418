.container {
  justify-content: center;
  margin-bottom: 16px;
}

.OTPInput {
  -moz-appearance: textfield;
  height: 32px !important;
  padding: 5px 12px;
  width: 48px !important;
  margin-right: 8px;
  border-radius: 4px;
  border: 1px solid rgba(35, 9, 3, 0.15);
  outline: 0 !important;
}

.OTPInput::-webkit-outer-spin-button,
.OTPInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.receiveCode {
  text-align: center;
  margin-bottom: 24px !important;
}

.resend {
  padding: 0;
  height: auto;
  margin-left: 5px;
}

.error {
  text-align: center;
  color: var(--color-red);
  margin: -10px 0 15px 0;
}
