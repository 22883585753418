.tableBtn {
  color: var(--color-orange-5) !important;

  &:not(.disabledBtn):hover,
  &:not(.disabledBtn):focus-visible,
  &:not(.disabledBtn):active {
    background-color: transparent !important;
  }

  &:not(.disabledBtn):hover {
    color: var(--color-orange-4) !important;
  }

  &.disabledBtn {
    color: var(--color-grey-3) !important;
  }
}

.image {
  width: 32px;
  height: 32px;
  object-fit: cover;
}

.name {
  max-width: 250px;
  word-break: break-all;
}

.email {
  max-width: 200px;
}
