.toolBarWrap {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  align-content: center;
  margin: 3px 0 21px 0;

  .title {
    margin: 0;
  }

  .deleteBtn {
    color: var(--color-white);
    background-color: var(--color-red-2);
    border-color: var(--color-red-2);

    &:hover,
    &:focus-visible,
    &:active {
      color: var(--color-white);
    }

    &:hover {
      background-color: var(--color-red);
      border-color: var(--color-red);
    }

    &.disabledBtn {
      border-color: var(--color-grey-3) !important;
    }
  }

  .actionsWrap {
    justify-content: flex-end;
  }
}



