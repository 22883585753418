.toolTip {
  color: var(--color-black-2);

  .title,
  .contentWrap > div {
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
  }

  .title {
    margin-bottom: 8px;
  }

  .contentWrap {
    display: grid;
    grid-gap: 10px;
    justify-content: space-between;
    grid-template-columns: 1fr auto;
    width: 100%;
  }
}
