.wrapper {
  background-color: var(--color-white);
  border-radius: 4px;
  padding: 8px 0 0 24px;
  height: 100%;

  .tableWrap {
    display: grid;
    margin-top: 16px;
    grid-template-columns: 135px 1fr;
  }
}
