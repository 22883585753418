.uploaderWrap {
  padding: 24px 0;
  overflow: hidden;

  .label {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .icon > svg {
    opacity: .45;
    width: 21px;
    height: 21px;
  }

  .withImage {
    > div {
      border: none !important;
      background-color: transparent !important;
    }
  }
}
