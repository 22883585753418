@import 'antd/dist/reset.css';

:root {
  --color-orange-1: #FFF5F0;
  --color-orange-2: #FFF6F0;
  --color-orange-3: #FFE7D9;
  --color-orange-4: #FFCAB0;
  --color-orange-5: #F3A482;
  --color-orange-6: #CC7E62;
  --color-orange-7: #A65D47;
  --color-orange-8: #804030;
  --color-orange-9: #592A21;
  --color-body: #230903D9;
  --color-black: #000000e0;
  --color-black-2: rgba(0, 0, 0, 0.88);
  --color-secondary-text: rgba(35, 9, 3, 0.45);
  --color-grey: #FAFAFA;
  --color-grey-3: #d9d9d9;
  --color-white: #fff;
  --color-red: #FF7A7A;
  --color-red-2: #FF4D4F;
  --color-dark-grey: #323232;
  --color-grey-2: #FCFCFC;
}

[class^="ant-btn"],
[class*=" ant-btn"],
html {
  font-family: 'Open Sans', sans-serif !important;
}

body {
  min-width: 1300px;
  font-family: 'Open Sans', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6,
.ant-pro-table-list-toolbar-title,
strong {
  font-weight: 600 !important;
}

.ant-layout-content {
  margin: 0;
  padding: 18px 24px;
  background-color: transparent;
}

.ant-menu {
  .ant-menu-item-selected {
    background-color: var(--color-orange-1);
    color: var(--color-orange-5);
  }

  .ant-menu-title-content a,
  .ant-menu-title-content {
    transition: color .1s !important;
  }

  .ant-menu-submenu-selected {
    .ant-menu-submenu-title {
      color: var(--color-black);
    }
  }
}

.ant-picker-dropdown .ant-picker-today-btn,
a {
  color: var(--color-orange-5);

  &:hover {
    color: var(--color-orange-4);
  }
}

input,
.ant-input-affix-wrapper-focused,
.ant-input-password,
.ant-input {
  &:not([disabled]) {
    &:hover {
      border-color: var(--color-orange-4) !important;
    }
  }
}

.ant-input-affix-wrapper-focused {
  border-color: var(--color-orange-4) !important;
  box-shadow: 0 0 0 2px var(--color-orange-1) !important;
}

input,
.ant-input-password,
.ant-input {
  &:not([disabled]) {
    &:focus,
    &:focus-visible {
      border-color: var(--color-orange-4) !important;
      box-shadow: 0 0 0 2px var(--color-orange-1) !important;
    }
  }
}

.ant-layout-sider-children {
  display: grid;
  grid-template-rows: 65px calc(100vh - 140px) 75px;
}

.ant-notification-notice {
  padding: 14px 17px;

  .ant-notification-notice-message {
    display: none;
  }

  .ant-notification-notice-description {
    margin-inline-start: 25px !important;
  }

  .ant-notification-notice-icon {
    margin-top: 4px;

    svg {
      width: 14px;
      height: 14px;
    }
  }

  .ant-notification-notice-close {
    display: none !important;
  }
}

.authFormWrapper {
  background-color: var(--color-white);
  border-radius: 12px;
  padding: 48px;
  max-width: 415px;

  .title {
    margin-bottom: 24px;
    text-align: center;

    &.withSubTitle {
      margin-bottom: 8px;
    }
  }

  .subTitle {
    color: var(--color-secondary-text);
    text-align: center;

    span {
      color: var(--color-body);

      strong {
        margin-left: 5px;
      }
    }
  }

  a {
    text-decoration: underline;
  }
}

.ant-btn {
  height: 34px;
}

.ant-btn-link {
  text-decoration: underline;
  color: var(--color-orange-5) !important;
  outline: 0 !important;

  &:disabled {
    cursor: not-allowed;
    color: rgba(0, 0, 0, 0.25) !important;
  }
}

.ant-btn-link:not([disabled]) {
  &:hover {
    color: var(--color-orange-4) !important;
  }
}

.authForm {
  .item {
    width: 320px;
  }

  .forgotPass {
    text-align: right;
  }

  .backToLogin {
    margin-bottom: 0;
    text-align: center;
  }
}

.ant-btn-primary {
  background-color: var(--color-orange-5);
  border-radius: 4px;
  padding: 5px 15px;

  &:not([disabled]) {
    &:active,
    &:focus-visible,
    &:focus {
      background-color: var(--color-orange-5);
    }

    &:hover,
    &:focus-visible {
      outline: 0 !important;
    }

    &:hover {
      background-color: var(--color-orange-4) !important;
    }
  }

  &[disabled] {
    color: var(--color-white);
    background-color: rgba(35, 9, 3, 0.25) !important;
  }
}

.ant-btn-default.ant-btn-dangerous {
  background-color: var(--color-red);
  border-color: var(--color-red);

  span {
    color: var(--color-white);
  }

  &:not([disabled]) {
    &:active {
      border-color: var(--color-red);
    }
  }
;
}

.ant-checkbox-wrapper,
.ant-pro-table-column-setting-action-rest-button,
.ant-pro-table-list-toolbar-setting-item:hover {
  font-weight: 400;
}

.ant-pro-table-list-toolbar-setting-item svg {
  font-size: 16px;
}

.ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: var(--color-orange-1);
  font-weight: 400;
}

.ant-pro-table-list-toolbar-left {
  flex: none;
}

.ant-select-dropdown .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: var(--color-grey-2);
}

.ant-pro-table-search-query-filter {
  .ant-col.ant-form-item-label {
    flex: 0 0 auto !important;
  }

  .ant-col.ant-form-item-control {
    width: calc(100% - 100px) !important;
  }
}

.ant-modal-confirm-btns {
  margin-top: 20px !important;
}

.ant-modal-content {
  padding: 32px 32px 24px 32px !important;
}

.ant-upload {
  margin: 0 !important;
  border-radius: 0 !important;
  width: 165px !important;
  height: 205px !important;
  overflow: hidden;
}

.uploadUser {
  .ant-upload {
    border-radius: 24px !important;
    width: 104px !important;
    height: 104px !important;
  }
}

.content-wrap {
  background-color: var(--color-white);
  border-radius: 6px;
}

.ant-picker,
.ant-select-selector {
  .ant-picker-input,
  .ant-select-selection-search {
    > input {
      box-shadow: none !important;
    }
  }
}

.g2-tooltip {
  min-width: 120px;
  padding: 8px !important;
  line-height: 15px !important;
  border-radius: 6px !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.ant-pro-table-list-toolbar-title {
  padding: 4px 0;
}

.customToolbar {
  .ant-pro-table-list-toolbar-container {
    flex-wrap: wrap;

    .ant-pro-table-list-toolbar-right,
    .ant-pro-table-list-toolbar-left {
      flex-basis: 100%;
    }

    .ant-pro-table-list-toolbar-right {
      margin-top: 20px;
      justify-content: flex-start;
    }
  }

  &.paddingLeft-12 {
    .ant-pro-card-body {
      padding-left: 12px;
    }
  }
  
  &.padding-inline {
    .ant-pro-card-body {
      padding-inline: 0;
    }
  }
}

.hide-native-search {
  .ant-pro-table-search {
    display: none;
  }
}

input:focus::-webkit-contacts-auto-fill-button {
  opacity: 0 !important;
}

.ant-menu-light.ant-menu-root.ant-menu-inline{
  border-inline-end: none;
}

.ant-radio-group.ant-radio-group-solid {
  background-color: rgba(0, 0, 0, 0.04);
  padding: 3px;
  border-radius: 4px;

  .ant-radio-button-wrapper {
    border: none;
    border-radius: 4px;
    color: var(--color-black-2);
  }

  .ant-radio-button-wrapper {
    background-color: transparent;

    &:before {
      content: none;
    }

    &.ant-radio-button-wrapper-checked {
      background-color: #fff;
      color: var(--color-orange-5);
    }
  }
}

.dataTable {
  .ant-table-tbody {
    cursor: pointer;
  }
}

.horses-products {
  .ant-form-item-label >label {
    position: relative;
    display: inline-flex;
    align-items: flex-end;
    max-width: 100%;
    height: 31px;
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
  }
  .ant-pro-card .ant-pro-card-body{
    padding-block: 0px;
  }
}

.horse-product-row {
  .ant-table-cell {
    padding: 9px 16px !important;
  }
}

.horse-product-head {
  padding: 9px 12px !important;
  border-start-start-radius: 0 !important;
  border-start-end-radius: 0 !important;
}