.tableBtn {
  color: var(--color-orange-5) !important;

  &:hover,
  &:focus-visible,
  &:active {
    background-color: transparent !important;
  }

  &:hover {
    color: var(--color-orange-4) !important;
  }
}

.name {
  max-width: 250px;
  word-break: break-all;
}

.email {
  max-width: 200px;
}
