.title {
  margin: 0 !important;
  padding: 8px 0 24px 0;

  .divider {
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 0;
  }
}
