.userActivity {
  display: grid;
  grid-gap: 8px;
  grid-template-columns: 1fr auto;
  border-bottom: 1px solid var(--color-grey-3);
  align-items: center;
  padding: 9px 16px;
  
  div{
    max-width: 200px;
  }

  &.isFirst {
    border-top: 1px solid var(--color-grey-3);
  }
}
